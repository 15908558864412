<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </el-row>

    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加图片库数据" :visible.sync="dialogVisible" :close-on-click-modal="false" width="600px" :before-close="handleClose">
      <el-form v-model="formData" label-width="150px">
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "BusImageLibrary",
  data() {
    return {
      queryInfo: {
        materialCode: '',
        pageNo: 1
      },
      formData: {
        materialCode: '',
      },
      dataList: [],
      total: 0,
      pageSize: 0,
      dialogVisible: false,
      loading: false
    }
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      this.$axios.post('busImageLibrary/queryPage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.dataList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('busImageLibrary/delete', row.materialCode).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    addRule() {
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空');
      }
      this.loading = true;
      this.$axios.post('busImageLibrary/add', this.formData.materialCode).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.$message.success("添加成功");
        this.search();
        this.handleClose()
        this.formData.materialCode = ''
      }, error => {
        this.loading = false;
        return this.$message.error('添加失败，' + error.message);
      });

    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>